import {html, LitElement} from 'lit';
import {customElement, property, state} from 'lit/decorators.js';
import {live} from 'lit/directives/live.js';

import {PubSub} from '@cad/static-next-lib';
import {tablet} from '../../js/services/const';
import viewport from '../../ts/services/viewport';

import {UrlBrandData} from './urlBrandData';
import {premiumPartner} from './renderPremiumPartner';
import {partners} from './renderPartners';
import {smoothScrollTool} from './renderSmoothScrollTool';
import {description} from './renderDescription';

import general from '../../ts/css/general.styles';
import elements from '../../ts/css/elements.styles';
import {iconsFont} from '../../ts/css/icons.styles';
import styles from './routenplaner.styles';


@customElement('ui-routenplaner')
export class Routenplaner extends LitElement {
  static styles = [general, elements, iconsFont, styles];

  @property() brandId = ''; // webde
  @property() brandName = ''; // WEB.DE
  @property() showDB = ''; // true

  @state() private urlBrandData?: UrlBrandData;
  @state() private showReloaded = false;

  @state() private fromstreet = '';
  @state() private fromcity = '';
  @state() private tostreet = '';
  @state() private tocity = '';
  @state() private mfz = '1';
  @state() private transport = 'car';
  @state() private timeSelection = 'depart';

  connectedCallback() {
    super.connectedCallback();

    if (this.brandId.length > 0 && this.brandName.length > 0) {
      this.urlBrandData = new UrlBrandData(this.brandId, this.brandName);
    }
  }

  handleSubmitForm(event: Event) {
    event.preventDefault();

    if (this.urlBrandData) {
      this.showReloaded = true;
      this.sendReloadBrainTrackingEvent();
      this.openPartnerURl(this.urlBrandData);
      PubSub.publish('ad:refreshAll');
    }
  }

  handleReloadedSubmitForm(event: Event) {
    event.preventDefault();

    if (this.urlBrandData) {
      let startAdr = '';
      let destAdr = '';
      let startDate = '';
      let startTime = '';
      let startDateName = 'startDate';
      let startTimeName = 'startTime';

      if (this.isMobileViewport()) {
        startDateName = 'startDateMobile';
        startTimeName = 'startTimeMobile';
      }

      const formData = new FormData(event.target as HTMLFormElement);
      for (const [key, value] of formData.entries()) {
        switch (key) {
          case 'startAdr':
            startAdr = value.toString();
            break;
          case 'destAdr':
            destAdr = value.toString();
            break;
          case startDateName:
            startDate = value.toString();
            break;
          case startTimeName:
            startTime = value.toString();
            break;
        }
      }

      this.openReloadedPartnerUrl(this.urlBrandData, startAdr, destAdr, startDate, startTime);
      this.resetViewState();
    }
  }

  handleResetRouteClick(event: MouseEvent) {
    event.stopPropagation();
    this.resetViewState();
  }

  handleChangeRouteClick(event: MouseEvent) {
    event.stopPropagation();
    this.showReloaded = false;
  }

  handleTransportClick(event: MouseEvent) {
    const htmlElement = event.target as HTMLElement;
    this.transport = htmlElement.id;
  }

  handleChangeDestinationWithStartClick() {
    const fromstreetCurrent = this.fromstreet;
    const fromcityCurrent = this.fromcity;
    const tostreetCurrent = this.tostreet;
    const tocityCurrent = this.tocity;

    this.fromstreet = tostreetCurrent;
    this.fromcity = tocityCurrent;
    this.tostreet = fromstreetCurrent;
    this.tocity = fromcityCurrent;
  }

  handleInvalid(event: Event) {
    event.preventDefault();
    const htmlElement = event.target as HTMLInputElement;
    htmlElement.classList.add('error');
  }

  handleChangeTimeSelection(event: Event) {
    const htmlElement = event.target as HTMLInputElement;
    this.timeSelection = htmlElement.value;
  }

  updatePropertyData(event: Event) {
    const inputElement = event.target as HTMLInputElement;

    switch (inputElement.name) {
      case 'fromstreet':
        this.fromstreet = this.capitalizeFirstLetterAndTrim(inputElement.value);
        break;
      case 'fromcity':
        this.fromcity = this.capitalizeFirstLetterAndTrim(inputElement.value);
        break;
      case 'tostreet':
        this.tostreet = this.capitalizeFirstLetterAndTrim(inputElement.value);
        break;
      case 'tocity':
        this.tocity = this.capitalizeFirstLetterAndTrim(inputElement.value);
        break;
    }
  }

  resetViewState() {
    this.fromstreet = '';
    this.fromcity = '';
    this.tostreet = '';
    this.tocity = '';
    this.transport = 'car';

    this.showReloaded = false;

    viewport.scrollViewPortToTop();
  }

  isMobileViewport(): boolean {
    return window.innerWidth < tablet;
  }

  capitalizeFirstLetterAndTrim(input: string) {
    if (input && input.length > 0) {
      const trimmedInput = input.trim();
      if (trimmedInput.length > 1) {
        return trimmedInput.charAt(0).toUpperCase() + trimmedInput.slice(1);
      } else {
        return trimmedInput;
      }
    }
    return '';
  }

  get referrer() {
    return 'https://' + location.hostname;
  }

  buildPartnerUri(brandId: string, region: string) {
    let tenant = brandId;
    let partneruri = 'https://';

    try {
      if (tenant === 'eue') {
        tenant = '1und1';
      }
      partneruri += (region === 'ch' ? 'de.viamichelin.ch' : 'www.viamichelin.de') +
        '/web/Routenplaner?' +
        '&strStartAddress=' + this.stringEncodeAndTrim(this.fromstreet) +
        '&strStartCity=' + this.stringEncodeAndTrim(this.fromcity) +
        '&strDestAddress=' + this.stringEncodeAndTrim(this.tostreet) +
        '&strDestCity=' + this.stringEncodeAndTrim(this.tocity) +
        '&strVehicle=' + ((this.transport === 'walk') ? '2' : ((this.transport === 'bike') ? '3' : '0')) +
        '&intItineraryType=1' +
        '&from=' + tenant +
        '&utm_campaign=Routenplaner_' + tenant + '_SearchButton';
    } catch (e) {
      partneruri = '';
    }
    return partneruri;
  }

  stringEncodeAndTrim(str: string) {
    return encodeURIComponent(str.trim());
  }

  openPartnerURl(urlBrandData: UrlBrandData) {
    const buildUrl = this.buildPartnerUri(urlBrandData.brandId, urlBrandData.uriRegion);
    window.open(urlBrandData.viamichelinBtnUrl + encodeURIComponent(buildUrl));
  }

  openReloadedPartnerUrl(urlBrandData: UrlBrandData, startAdr: string, destAdr: string, startDate: string, startTime: string) {
    const getStart = encodeURIComponent(startAdr);
    const getDest = encodeURIComponent(destAdr);
    const getTime = `${startDate}T${startTime}`; // yyyy-MM-ddTHH:mm:ss
    const dbPart = urlBrandData.brandId === 'webde' ? 'WEB' : 'GMX';
    const getTimeSelection = this.timeSelection === 'depart' ? 'D' : 'A';

    const affilinetUrl = 'https://www.awin1.com/cread.php?awinmid=14964&awinaffid=570453&linkid=3441813&p=';

    const bahnUrl = 'https://www.bahn.de/buchung/start?STS=true' +
          '&dbkanal_003=L01_S01_D001_KAF0001_AFFILIATE-KOOP-ROUTENPLANER-' + dbPart + '-!!!affid!!!_LZ03' +
          '&SO=' + getStart +
          '&ZO=' + getDest +
          '&HD=' + getTime +
          '&HZA=' + getTimeSelection;

    window.open(affilinetUrl + encodeURIComponent(bahnUrl), '_blank');
  }

  createDateInFuture(minutes: number): Date {
    return new Date(new Date().getTime() + minutes * 60000);
  }

  sendReloadBrainTrackingEvent() {
    PubSub.publish('staticnext:sendPIPixel', {
      referrer: `&referrer=${this.referrer}`,
      click1: '&click1=undefined',
      click2: '&click2=undefined',
      click3: '&click3=undefined',
      click4: '&click4=undefined',
      clickname: '&clickname=undefined'
    });
  }

  render() {
    if (this.urlBrandData === undefined) {
      return html`<div>brandId or brandName missing!</div>`;
    }

    if (this.showReloaded) {
      return html`
        ${this.reloaded(this.urlBrandData)}
      `;
    } else {
      return html`
        <section class="routenplanerSection" data-site="startpage">
          <h1 class="page__headline">Routenplaner &ndash; Ihre kostenlose Routenplanung</h1>

          <div class="page__content">
            <div class="head-wrapper">
              <p>
                Routenplaner: Unser kostenloser Service zur Reiseplanung berechnet Ihnen europaweit die optimale Route zu
                Ihrem Wunschziel. Routenplanung leicht gemacht - hier finden Sie mühelos die besten Strecken durch
                Deutschland und Europa. Der ${this.urlBrandData.brandName} Routenplaner bringt Sie immer direkt ans Ziel.
              </p>
            </div>

            <slot name="berechnen_sie_ihre_route"></slot>

            <form id="routenplaner" class="routenplaner" name="routenplaner" @submit=${this.handleSubmitForm}>
              ${this.routenplaner()}
              ${premiumPartner(this.urlBrandData)}
              ${smoothScrollTool(this.urlBrandData)}
            </form>

            ${partners(this.urlBrandData)}
            ${description(this.urlBrandData)}
            <slot name="weitere_services"></slot>
          </div>
        </section>
      `;
    }
  }

  routenplaner() {
    const streetPlaceholder = this.brandId === 'gmxch' ? 'Strasse' : 'Straße';
    return html`
      <div class="formWrapper">
        <div class="inputField">
          <label for="routestartcity">Startadresse</label>
          <input id="routestartstreet" name="fromstreet" class="street startstreet" type="text" maxlength="100" placeholder="${streetPlaceholder}" .value="${live(this.fromstreet)}" @change=${this.updatePropertyData} />
          <input id="routestartcity" name="fromcity" class="city startcity" required type="text" maxlength="100" placeholder="Ort" .value="${live(this.fromcity)}" @change=${this.updatePropertyData} @invalid="${this.handleInvalid}" />

          <div class="err-hint from city">Bitte geben Sie einen Startort ein</div>

          <div class="icon icon-change" title="Start und Ziel tauschen" @click=${this.handleChangeDestinationWithStartClick}></div>
          <div class="clearBoth"></div>

          <label for="routedestinationcity">Zieladresse</label>
          <input id="routedestinationstreet" name="tostreet" class="street destinationstreet" maxlength="100" type="text" placeholder="${streetPlaceholder}" .value="${live(this.tostreet)}" @change=${this.updatePropertyData} />
          <input id="routedestinationcity" name="tocity" class="city destinationcity" required maxlength="100" type="text" placeholder="Ort" .value="${live(this.tocity)}" @change=${this.updatePropertyData} @invalid="${this.handleInvalid}" />

          <div class="err-hint to city">Bitte geben Sie einen Zielort ein</div>

          <div class="carSharing">
            <input id="mitfahrzentrale" name="mfz" type="checkbox" value="${this.mfz}" checked />
            <p class="note">Jetzt günstiges Hotel an Ihrem Zielort finden.</p>
          </div>

          <div class="sendWrapper">
            <span id="car" class="icon icon-car js-transport ${this.transport === 'car' ? 'active' : ''}" data-param="car" title="Mit dem Auto" @click=${this.handleTransportClick}></span>
            <span id="bike" class="icon icon-bike js-transport ${this.transport === 'bike' ? 'active' : ''}" data-param="bike" title="Mit dem Fahrrad" @click=${this.handleTransportClick}></span>
            <span id="walk" class="icon icon-walk js-transport ${this.transport === 'walk' ? 'active' : ''}" data-param="walk" title="Zu Fuß" @click=${this.handleTransportClick}></span>
            <p class="button-right">
              <button type="submit" class="button js-submit primary" data-partner="viamichelin">Route berechnen</button>
            </p>
          </div>

          <input type="hidden" id="transport" name="transport" value="${this.transport}" />
          <input type="hidden" name="template" value="reloaded" />
        </div>
      </div>
    `;
  }

  reloaded(urlBrandData: UrlBrandData) {
    const startAdr = this.fromstreet?.length > 0 ? this.fromstreet : this.fromcity;
    const destAdr = this.tostreet?.length > 0 ? this.tostreet : this.tocity;
    return html`
      <section class="routenplanerSection" data-site="reloaded">
        <h1 class="page__headline">Routenplaner – Ihre kostenlose Routenplanung</h1>

        <div class="page__content">

          <div class="reloadedSourceDestination">

            <div class="startDestinationWrapper">
              <span class="startAdr">Start</span>
              <p>${startAdr}</p>
            </div>
            <div class="startDestinationWrapper">
              <span class="destAdr">Ziel</span>
              <p>${destAdr}</p>
            </div>

            <form id="routenplanerback" name="routenplanerback" novalidate>
              <p class="button-right">
                <a class="button white js-route-back" @click=${this.handleResetRouteClick}>Neue Route</a>
                <button class="button js-submit primary" @click=${this.handleChangeRouteClick}>Route ändern</button>
              </p>
            </form>

          </div>

          ${this.showDB === 'true' ? html`<div class="reloadedPartners">${this.reloadedPartnerDB(urlBrandData, this.fromcity, this.tocity)}</div>` : ''}

        </div>
        </section>
    `;
  }

  reloadedPartnerDB(urlBrandData: UrlBrandData, startAdr: string, destAdr: string) {
    const date = this.createDateInFuture(5);
    const startDate = date.toJSON().slice(0, 10);
    const startTime = date.toLocaleTimeString('de-DE', {hour: 'numeric', minute: 'numeric'});
    const logoPath = urlBrandData.assetsPath + '/routenplaner/logo-db.svg';
    return html`
      <div class="partners clearfix">
        <h2>Klimafreundliche Alternative der Bahn buchen – schon ab 17,90€</h2>

        <div class="partnerPrivacyInformation" data-speakable>
          Dieser Service ist mit sogenannten Partner-Links versehen: Wenn Sie über einen solchen Link ein Produkt bestellen,
          bekommen wir in einigen Fällen eine Provision. Der Preis für Sie bleibt dabei unverändert. Um Provisionen zuordnen
          zu können, nutzen unsere Partner erforderliche Cookies, die bei Klick auf die Partner-Links gesetzt werden.
        </div>

        <div class="partnerSection">
          <div class="centered">
            <a class="partnerLogo" rel="nofollow" target="_blank"
              href="https://www.bahn.de/service">
              <img src="${logoPath}" alt="Deutsche Bahn Logo" />
            </a>
          </div>
          <div class="partnerTextWrapper">
            <h4 class="partnerName">Reiseauskunft</h4>

            <p class="partnerText">Bequem am Stau vorbei mit Bus und Bahn zum Ziel.</p>
          </div>

          <div class="partnerformWrapper">
            <div class="inputField">
              <form id="db-searchform" class="db js-partner" data-formname="db-searchform" @submit=${this.handleReloadedSubmitForm}>

                <!--Start-->
                <label for="db-query">Start</label>
                <input id="db-query" class="db-query" type="text" placeholder="Von Bahnhof/Haltestelle" name="startAdr"
                  value="${startAdr}" />
                <div class="err-hint">Bitte geben Sie eine Startadresse ein</div>

                <!--Ziel-->
                <label for="db-location">Ziel</label>
                <input id="db-location" class="db-location" type="text" placeholder="... nach Bahnhof/Haltestelle"
                  name="destAdr" value="${destAdr}" />
                <div class="err-hint">Bitte geben Sie eine Zieladresse ein</div>

                <label for="db_traveldayD" class="db-travelday-label js-table-label">Reisetag</label>

                ${this.isMobileViewport() ? this.reloadedPartnerDBMobile(startDate, startTime) : this.reloadedPartnerDBDesktop(startDate, startTime)}

                <input type="hidden" name="dbkanal_004" value="L01_S01_D001_KPK0022_routenplaner-formular-web_LZ03" />

                <p class="button-right">
                  <button type="submit" class="button secondary js-submit" data-partner="db">Suchen</button>
                </p>

              </form>
            </div>
          </div>

        </div>
      </div>
    `;
  }

  reloadedPartnerDBDesktop(startDate: string, startTime: string) {
    return html`
      <div class="dbTable js-table">
        <div class="dbRow">
          <div class="dbCell db-date">
            <input id="db_traveldayD" class="arrival" type="date" placeholder="Reisetag" name="startDate"
              value="${startDate}" />
            <div class="err-hint">Bitte geben Sie ein Datum ein</div>
          </div>
          <div class="dbCell db-clock clearfix">
            <input id="db_traveltimeD" class="db-mobileClock" type="time" placeholder="U" name="startTime"
              value="${startTime}" />
            <div class="err-hint">Bitte geben Sie eine Uhrzeit ein</div>
          </div>
          <div class="dbCell db-timewhish clearfix">
            <div class="radioGroup">
              <div class="radioRow">
                <input id="departure" type="radio" class="custom-radio" name="db-timesel" value="depart"
                  ?checked=${this.timeSelection === 'depart'} @change=${this.handleChangeTimeSelection} />
                <label for="departure">Abfahrt</label>
                <input id="arrival" type="radio" class="custom-radio" name="db-timesel" value="arrive"
                  ?checked=${this.timeSelection === 'arrive'} @change=${this.handleChangeTimeSelection} />
                <label for="arrival">Ankunft</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    `;
  }

  reloadedPartnerDBMobile(startDate: string, startTime: string) {
    return html`
      <div class="dbTableMobile js-table-mobile">
        <label for="db_traveldayM" class="db-travelday-label">Reisetag</label>
        <input id="db_traveldayM" class="arrival" type="date" placeholder="Reisetag" name="startDateMobile" value="${startDate}" />
        <div class="err-hint">Bitte geben Sie ein Datum ein</div>
        <label for="db_traveltimeM">um</label>
        <input id="db_traveltimeM" class="db-mobileClock" type="time" placeholder="U" name="startTimeMobile" value="${startTime}" />
        <div class="err-hint">Bitte geben Sie eine Uhrzeit ein</div>
        <div class="radioGroup">
          <div class="radioRow">
            <input id="mobiledeparture" type="radio" class="custom-radio" name="db-timesel" value="depart"
              ?checked=${this.timeSelection === 'depart'} @change=${this.handleChangeTimeSelection} />
            <label for="mobiledeparture">Abfahrt</label>
            <input id="mobilearrival" type="radio" class="custom-radio" name="db-timesel" value="arrive"
              ?checked=${this.timeSelection === 'arrive'} @change=${this.handleChangeTimeSelection} />
            <label for="mobilearrival">Ankunft</label>
          </div>
        </div>
      </div>
    `;
  }
}
