import {html} from 'lit';
import {UrlBrandData} from './urlBrandData';

export function description(urlBrandData: UrlBrandData) {
  return html`
    <div id="ueber_den_${urlBrandData.brandId}_routenplaner">
      <slot name="ueber_den_routenplaner"></slot>

      <div>
        <p>Berechnen Sie Ihre Route zu Ihrem Ziel schnell und kostenlos mit unserem Routenplaner.</p>

        <p>
          Der ${urlBrandData.brandName} Routenplaner bringt Sie über die besten, schnellsten oder schönsten Strecken
          ans Ziel. Unser Routenplaner ermöglicht in Zusammenarbeit mit ViaMichelin und
          anderen Partnern die perfekte Reiseplanung.
        </p>

        <p>
          Erfahren Sie hier online die Informationen, die Sie für Ihre Reise mit dem Auto, Motorrad,
          Fahrrad oder auch zu Fuß benötigen, um an Ihr Wunschziel in ganz Europa zu gelangen. Mit
          unserer kostenlosen Routenplanung erhalten Sie nicht nur eine Übersicht, sondern eine
          genaue Wegbeschreibung, alternative Routen und können Ihre Fahrstrecke auch auf einer
          Landkarte individuell nachverfolgen. Lassen Sie sich nicht nur Ihre Kilometer berechnen,
          sondern auch die Fahrstrecke und Dauer auf einer interaktiven Karte aufzeigen. Mit allem was
          dazu gehört: genaue Streckenbeschreibung, Kilometerangaben, Fahrtzeit, Richtungsänderung,
          Bezeichnung der Straße, Straßenschilder und vieles mehr. So können Sie auch auf der Strecke
          die restliche Entfernung zu Ihrem Reiseziel erfahren, sich aktuelle Verkehrsinformationen
          einholen und im Bedarfsfall Ihre Route ändern. Bei Staus und Behinderungen, können Sie mit
          dem ${urlBrandData.brandName} Routenplaner bequem Alternativrouten finden und so die schnellste Strecke
          einschlagen.
        </p>

        <p>
          An Ihrem Wunschziel oder im Urlaub können Sie sich einen Stadtplan erstellen lassen, um
          alle Sehenswürdigkeiten schnell und einfach zu finden und so Ihren Aufenthalt optimal
          planen. Auch der Rückweg zu Ihrem Hotel wird so zum Kinderspiel. Mit der Umkreissuche
          erhalten Sie auf einer Landkarte Tipps zu nahegelegenen Restaurants oder weiteren
          touristischen Attraktionen
        </p>

        <p>
          Die Bedienung unseres kostenlosen Routenplaners ist ganz einfach. Zuerst geben Sie die
          Startadresse sowie Ihr Ziel an. In der Regel werden Ihnen mehrere Routen vorgeschlagen. Auf
          der angezeigten Karte können Sie sich dann die optimale Route für Ihre Planung aussuchen
          und anklicken. Ebenfalls bieten wir Ihnen über unsere Partner, wie beispielsweise Michelin
          eine Auswahl mehrerer Anbieter um Ihnen die perfekte Planung in den Urlaub, ein
          anderes Land oder auch innerhalb einer Stadt zu ermöglichen.
        </p>
      </div>
    </div>
  `;
}
