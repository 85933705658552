import {html} from 'lit';
import {UrlBrandData} from './urlBrandData';
import viewport from '../../ts/services/viewport';

export function smoothScrollTool(urlBrandData: UrlBrandData) {
  return html`
    <div class="mod-tool" data-mod-name="tool" data-mod-nojs data-mod-nocss>
      <div class="tool-header">
        <h4>Inhalt</h4>
      </div>
      <div class="tool-content">
        <div data-mod-name="toc" data-mod-nocss>
          <ul class="list master">
            <li><a @click="${scrollToHeadline}" href="#berechnen_sie_ihre_route">Berechnen Sie Ihre Route</a></li>
            <li><a @click="${scrollToHeadline}" href="#weitere_routenplaner_im_web">Weitere Routenplaner im Web</a></li>
            <li><a @click="${scrollToHeadline}" href="#ueber_den_routenplaner">Über den ${urlBrandData.brandName} Routenplaner</a></li>
            ${urlBrandData.brandId === 'webde' ? html`
            <li><a @click="${scrollToHeadline}" href="#weitere_services_von_webde">Weitere Services von ${urlBrandData.brandName}</a></li>
            ` : ''}
          </ul>
        </div>
      </div>
    </div>
  `;
}

const scrollToHeadline = (e: Event) => {
  const anchor = e.target as HTMLAnchorElement;
  if (anchor && anchor.href) {
    e.preventDefault();

    const id = anchor.hash;
    const target = document.querySelector(id);
    if (target) {
      const offsetTop = (viewport.matches('desktop')) ? 115 : 50;
      viewport.scrollViewPortTo(target, offsetTop);
    }
  }
};
