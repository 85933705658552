import {css} from 'lit';
import {smartphone, smartphoneOnly, tablet, large, large1440, large1920} from '../../js/services/const';

export default css`
  :host {
    --bullet-size: 0.6rem;
    --line-height: 2.4rem;
  }
  .head-wrapper {
    margin-top: 1.4rem;
    margin-bottom: 0.6rem;
    overflow: hidden;
    color: var(--text-highVisibility);
  }

  .head-wrapper p {
    margin: 0;
    font-size: 1.6rem;
    line-height: 2.6rem;
  }

  @media (min-width: ${tablet}px) {
    .head-wrapper p {
      line-height: 2.6rem;
      font-size: 1.6rem;
    }
  }

  .page__headline {
    padding-top: 3.2rem;
    margin-top: 0;
    font-size: 2.4rem;
    line-height: 2.8rem;
  }

  @media (max-width: ${smartphoneOnly}px) {
    .page__headline {
      padding-top: 1.6rem;
    }
  }
  @media (min-width: ${tablet}px) {
    .page__headline {
      font-size: 3.2rem;
      line-height: 4.0rem;
    }
  }
  @media (min-width: ${large1440}px) {
    .page__headline {
      font-size: 4rem;
      line-height: 4.8rem;
    }
  }
  @media (min-width: ${large1920}px) {
    .page__headline {
      font-size: 4.6rem;
      line-height: 5.4rem;
    }
  }

  .routenplanerSection {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  @media (min-width: ${smartphone}px) {
    .routenplanerSection {
      padding-left: var(--article-padding);
      padding-right: var(--article-padding);
    }
  }
  @media (min-width: ${large1440}px) {
    .routenplanerSection {
      padding-left: calc(var(--article-padding) * 2);
      padding-right: calc(var(--article-padding) * 2);
    }
  }

  .routenplanerSection.left {
    padding: 0;
  }

  .routenplanerSection .carSharing {
    clear: both;
    margin: 0 0 0.5rem 0;
  }

  @media (min-width: ${tablet}px) {
    .routenplanerSection .carSharing {
      margin: 0 0 1.6rem 0;
    }
  }

  .routenplanerSection .carSharing input, .routenplanerSection .carSharing .note {
    display: none;
  }

  .routenplanerSection .page__headline {
    font-size: 2.2rem;
    line-height: 2.6rem;
  }

  @media (min-width: ${tablet}px) {
    .routenplanerSection .page__headline {
      font-size: 2.6rem;
      line-height: 3.2rem;
    }
  }
  @media (min-width: ${large1440}px) {
    .routenplanerSection .page__headline {
      font-size: 3.4rem;
      line-height: 4rem;
    }
  }

  .routenplanerSection .err-hint {
    margin: 0 0 0.8rem 0;
    color: var(--notification-error);
    display: none;
    line-height: 1.6rem;
    float: left;
  }

  .routenplanerSection .icon {
    color: var(--grey-hover);
  }

  .routenplanerSection .icon:hover {
    color: var(--text-highVisibility);
  }

  .routenplanerSection .icon.active {
    color: var(--text-highVisibility);
  }

  .routenplanerSection .icon.icon-change {
    position: absolute;
    top: 14.9rem;
    right: -0.5rem;
    width: 4rem;
    height: 4rem;
    font-size: 2.2rem;
    line-height: 4.2rem;
    cursor: pointer;
  }

  .routenplanerSection .icon.icon-change:before {
    margin-left: 1rem;
  }

  @media (min-width: ${tablet}px) {
    .routenplanerSection .icon.icon-change {
      top: 4.5rem;
      right: 0;
      width: 2rem;
      height: 2rem;
      left: 11.1rem;
      line-height: 100%;
    }

    .routenplanerSection .icon.icon-change:before {
      margin: 0;
    }
  }

  .routenplanerSection img {
    width: auto;
  }

  .routenplanerSection form {
    margin: 0;
    padding: 0;
  }

  .routenplanerSection form input[type='text'] {
    width: 100%;
    float: left;
    font-family: var(--font-family-sans-serif);
    margin: 0 0 1.6rem 0;
  }

  .routenplanerSection form input[type='text']::placeholder {
    color: var(--cp-grey-light-3);
    font-style: italic;
  }

  .routenplanerSection form input[type='text'].error {
    margin-bottom: 0.8rem;
  }

  @media (min-width: ${tablet}px) {
    .routenplanerSection form input[type='text'].street, .routenplanerSection form .err-hint.street {
      width: 49%;
      box-sizing: border-box;
      float: left;
    }
  }
  @media (min-width: ${tablet}px) {
    .routenplanerSection form input[type='text'].city, .routenplanerSection form .err-hint.city {
      width: 49%;
      box-sizing: border-box;
      float: right;
    }
  }

  .routenplanerSection form.airbnb label, .routenplanerSection form.routenplaner label {
    font-family: var(--font-family-sans-serif);
    font-weight: 700;
    text-align: left;
    float: left;
    clear: both;
  }

  @media (min-width: ${tablet}px) {
    .routenplanerSection form.airbnb label, .routenplanerSection form.routenplaner label {
      float: none;
    }
  }

  .routenplanerSection form.airbnb label:first-child, .routenplanerSection form.routenplaner label:first-child {
    position: relative;
  }

  @media (min-width: ${tablet}px) {
    .routenplanerSection form.airbnb label:first-child, .routenplanerSection form.routenplaner label:first-child {
      position: absolute;
      top: 0;
      left: 0;
      width: 13.2rem;
      text-align: right;
    }
  }

  .routenplanerSection form.airbnb label:nth-of-type(2), .routenplanerSection form.routenplaner label:nth-of-type(2) {
    position: relative;
    top: 0;
  }

  @media (min-width: ${tablet}px) {
    .routenplanerSection form.airbnb label:nth-of-type(2), .routenplanerSection form.routenplaner label:nth-of-type(2) {
      position: absolute;
      top: 7.1rem;
      left: 0;
      width: 13.2rem;
      text-align: right;
    }
  }

  .routenplanerSection form.airbnb label:nth-of-type(3), .routenplanerSection form.routenplaner label:nth-of-type(3) {
    position: relative;
    top: 0;
  }

  @media (min-width: ${tablet}px) {
    .routenplanerSection form.airbnb label:nth-of-type(3), .routenplanerSection form.routenplaner label:nth-of-type(3) {
      position: absolute;
      top: 11.5rem;
      left: 0;
    }
  }

  .routenplanerSection form.airbnb label:nth-of-type(4), .routenplanerSection form.routenplaner label:nth-of-type(4) {
    position: relative;
    top: 0;
  }

  @media (min-width: ${tablet}px) {
    .routenplanerSection form.airbnb label:nth-of-type(4), .routenplanerSection form.routenplaner label:nth-of-type(4) {
      position: absolute;
      top: 16.5rem;
      left: 0;
    }
  }

  .routenplanerSection .formWrapper {
    width: 100%;
    box-sizing: border-box;
    position: relative;
  }

  .routenplanerSection .sendWrapper {
    padding-top: 0.5rem;
    clear: both;
    cursor: default;
  }

  .routenplanerSection .sendWrapper .icon {
    font-size: 2.4rem;
    margin-bottom: 0.8rem;
    padding-right: 0.6rem;
  }

  @media (min-width: ${tablet}px) {
    .routenplanerSection .sendWrapper .icon {
      margin-bottom: 0;
    }
  }

  .routenplanerSection .sendWrapper .button-right {
    margin-top: 0;
  }

  @media (min-width: ${tablet}px) {
    .routenplanerSection .sendWrapper .button-right {
      margin-top: -4rem;
    }
  }

  .routenplanerSection .sendWrapper span {
    cursor: pointer;
  }

  .routenplanerSection .startcity.error + .err-hint, .routenplanerSection .destinationcity.error + .err-hint {
    display: block;
    margin-bottom: 2rem;
  }

  .routenplanerSection .inputField {
    width: 100%;
  }

  @media (min-width: ${tablet}px) {
    .routenplanerSection .inputField {
      float: left;
      position: relative;
      width: 100%;
      box-sizing: border-box;
      padding-left: 15.3rem;
    }
  }

  .routenplanerSection .inputField .clearBoth {
    width: 100%;
    height: var(--gap);
  }

  .routenplanerSection .note {
    float: none;
    font-size: 1.4rem;
    color: var(--cp-grey-light-3);
    margin: 0 0 0.5rem 0;
    padding-left: 1rem;
    line-height: 2rem;
    display: table;
    font-family: var(--font-family-sans-serif);
  }

  @media (min-width: ${tablet}px) {
    .routenplanerSection .note {
      margin: 0 0 1.6rem 0;
    }
  }

  .routenplanerSection .partners {
    clear: both;
  }

  .routenplanerSection .partners img {
    border: 0.1rem solid var(--separator-default);
  }

  .routenplanerSection .premiumPartner {
    float: left;
    margin-top: 2rem;
    margin-bottom: 3.2rem;
    text-align: center;
  }

  @media (min-width: ${tablet}px) {
    .routenplanerSection .premiumPartner {
      text-align: left;
    }
  }

  .routenplanerSection .premiumPartner img {
    float: none;
    border: 0.1rem solid var(--separator-default);
    margin-bottom: 2.4rem;
  }

  @media (min-width: ${tablet}px) {
    .routenplanerSection .premiumPartner img {
      float: left;
      margin-bottom: 0;
    }
  }

  .routenplanerSection .premiumPartner .partnerText {
    margin-left: 0;
    text-align: left;
    font-size: 1.4rem;
    line-height: 1.8rem;
    font-family: var(--font-family-sans-serif);
  }

  @media (min-width: ${tablet}px) {
    .routenplanerSection .premiumPartner .partnerText {
      margin-left: 15.3rem;
    }
  }
  @media (min-width: ${large}px) {
    .routenplanerSection .premiumPartner .partnerText {
      font-size: 1.6rem;
      line-height: 2.2rem;
    }
  }

  .routenplanerSection .partnerSection {
    text-align: center;
    margin-bottom: 1.6rem;
    overflow: hidden;
  }

  .routenplanerSection .partnerSection img {
    margin-bottom: 1.6rem;
  }

  .routenplanerSection .partnerSection:not(:last-child) {
    border-bottom: 0.1rem solid var(--separator-default);
  }

  .routenplanerSection .partnerSection .partnerName {
    text-align: left;
    margin: 0;
    font-size: 1.4rem;
    line-height: 2rem;
  }

  @media (min-width: ${large}px) {
    .routenplanerSection .partnerSection .partnerName {
      font-size: 1.6rem;
      line-height: 2.2rem;
    }
  }

  .routenplanerSection .partnerSection .partnerText {
    text-align: left;
    font-size: 1.4rem;
    line-height: 1.8rem;
    font-family: var(--font-family-sans-serif);
  }

  @media (min-width: ${large}px) {
    .routenplanerSection .partnerSection .partnerText {
      font-size: 1.6rem;
      line-height: 2.2rem;
    }
  }

  .routenplanerSection .partnerSection .partnerText a {
    white-space: nowrap;
  }

  .routenplanerSection .partnerSection .partnerTextWrapper {
    margin-top: 1.6rem;
  }

  @media (min-width: ${tablet}px) {
    .routenplanerSection .partnerSection {
      text-align: left;
    }

    .routenplanerSection .partnerSection img {
      float: left;
      max-width: 13rem;
    }

    .routenplanerSection .partnerSection .partnerTextWrapper {
      margin-top: 0;
      margin-left: 15.3rem;
    }
  }

  .routenplanerSection .partnerPrivacyInformation {
    font-family: var(--font-family-sans-serif);
    font-size: 1.4rem;
    line-height: 2rem;
    color: var(--cp-grey-light-3);
    margin-bottom: 1.6rem;
  }

  .routenplanerSection button[type='submit'], .routenplanerSection button[type='button'] {
    float: right;
    margin: 1.8rem 0 0.8rem 0;
  }

  .routenplanerSection .button-right {
    text-align: right;
    clear: none;
  }

  @media (min-width: ${tablet}px) {
    .routenplanerSection .button-right {
      clear: both;
    }
  }

  .routenplanerSection .button-right .button {
    display: inline-block;
    float: none;
    margin: 0;
  }

  .routenplanerSection .button-right .button.white {
    margin-right: 1.6rem;
  }

  .reloadedSourceDestination {
    border-bottom: 0.1rem solid var(--separator-default);
    border-top: 0;
    position: relative;
    padding: 1.6rem 0;
    margin-top: 1.6rem;
    margin-bottom: 1.6rem;
  }

  @media (min-width: ${tablet}px) {
    .reloadedSourceDestination {
      border-top: 0.1rem solid var(--separator-default);
    }
  }

  .reloadedSourceDestination .startDestinationWrapper {
    float: left;
    padding: 0 0 1.2rem 0;
    width: 100%;
  }

  .reloadedSourceDestination .startDestinationWrapper .startAdr, .reloadedSourceDestination .startDestinationWrapper .destAdr {
    float: none;
    margin: 0;
    text-align: left;
    font-family: var(--font-family-sans-serif);
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 3.8rem;
  }

  @media (min-width: ${tablet}px) {
    .reloadedSourceDestination .startDestinationWrapper .startAdr, .reloadedSourceDestination .startDestinationWrapper .destAdr {
      font-size: 1.6rem;
      line-height: 3.2rem;
      text-align: right;
      max-width: 13rem;
      width: 13rem;
      float: left;
    }
  }

  .reloadedSourceDestination .startDestinationWrapper p {
    padding: 0;
    margin: 0;
    line-height: 3.2rem;
    font-family: var(--font-family-sans-serif);
  }

  @media (min-width: ${tablet}px) {
    .reloadedSourceDestination .startDestinationWrapper p {
      margin: 0 0 0 15.5rem;
    }
  }

  .reloadedSourceDestination .button-right {
    margin-bottom: 0;
  }

  .partnerformWrapper label {
    text-align: left;
  }

  @media (min-width: ${tablet}px) {
    .partnerformWrapper label {
      width: 13rem;
      text-align: right;
    }
  }

  .partnerformWrapper .custom-dropdown, .partnerformWrapper select {
    width: 100%;
  }

  .reloadedPartners .partners {
    border-bottom: 0.1rem solid var(--separator-default);
  }

  @media (min-width: ${tablet}px) {
    .reloadedPartners .partners:first-child {
      display: block;
    }
  }
  @media (min-width: ${tablet}px) {
    .reloadedPartners .partnerTextWrapper {
      min-height: 7rem;
    }
  }

  .reloadedPartners .calendarWrapper {
    position: relative;
    display: inline;
  }

  .reloadedPartners .calendarWrapper .icon-arrow {
    position: absolute;
    top: 0;
    font-size: 2.6rem;
    right: 0;
  }

  .reloadedPartners .partnerSection {
    text-align: left;
  }

  .reloadedPartners .partnerSection .centered {
    text-align: center;
  }

  .reloadedPartners .partnerSection .partnerformWrapper {
    display: block;
  }

  .reloadedPartners .partnerSection .partnerformWrapper form {
    margin: 0;
    padding: 0;
  }

  .reloadedPartners .partnerSection .partnerformWrapper form input[type='text'] {
    width: 100%;
    float: left;
    font-family: var(--font-family-sans-serif);
    margin: 0 0 1.6rem 0;
  }

  .reloadedPartners .partnerSection .partnerformWrapper form input[type='text']::placeholder {
    color: var(--cp-grey-light-3);
    font-style: italic;
  }

  @media (min-width: ${tablet}px) {
    .reloadedPartners .partnerSection .partnerformWrapper form input[type='text'].calendar {
      width: 40%;
    }

    .reloadedPartners .partnerSection .partnerformWrapper form input[type='text'].calendar.arrival {
      float: left;
    }

    .reloadedPartners .partnerSection .partnerformWrapper form input[type='text'].calendar.departure {
      float: right;
    }
  }

  .reloadedPartners .partnerSection .partnerformWrapper form input[type='text'].error {
    margin-bottom: 0.8rem;
  }

  .reloadedPartners .partnerSection .partnerformWrapper form label {
    font-family: var(--font-family-sans-serif);
    font-weight: 700;
    text-align: left;
    float: left;
    clear: both;
  }

  @media (min-width: ${tablet}px) {
    .reloadedPartners .partnerSection .partnerformWrapper form label {
      float: left;
      width: auto;
    }
  }

  .reloadedPartners .partnerSection .partnerformWrapper form.db input[type='text'] {
    margin: 0 1.6rem 1.6rem 0;
  }

  .reloadedPartners .partnerSection .partnerformWrapper form.db input[type='text'].error {
    margin-bottom: 0.8rem;
  }

  .reloadedPartners .partnerSection .partnerformWrapper form.db .labelWrapper {
    font-family: var(--font-family-sans-serif);
    font-weight: 700;
    margin-top: -1.6rem;
    min-width: var(--gap);
  }

  .reloadedPartners .partnerSection .partnerformWrapper form.db label {
    font-family: var(--font-family-sans-serif);
    font-weight: 700;
    text-align: left;
    float: left;
    clear: none;
  }

  @media (min-width: ${tablet}px) {
    .reloadedPartners .partnerSection .partnerformWrapper form.db label {
      float: left;
      width: auto;
    }
  }

  .reloadedPartners .partnerSection .partnerformWrapper form.db label.db-travelday-label {
    clear: both;
  }

  .reloadedPartners .partnerSection .partnerformWrapper form.db label:first-child {
    position: relative;
  }

  @media (min-width: ${tablet}px) {
    .reloadedPartners .partnerSection .partnerformWrapper form.db label:first-child {
      margin-left: -4.7rem;
    }
  }

  .reloadedPartners .partnerSection .partnerformWrapper form.db label:nth-of-type(2) {
    position: relative;
    top: 0;
    clear: both;
  }

  @media (min-width: ${tablet}px) {
    .reloadedPartners .partnerSection .partnerformWrapper form.db label:nth-of-type(2) {
      margin-left: -3.9rem;
    }
  }

  .reloadedPartners .partnerSection .partnerformWrapper form.db .radioGroup {
    margin-left: 0.1rem;
    margin-bottom: 1.6rem;
    float: left;
  }

  @media (min-width: ${tablet}px) {
    .reloadedPartners .partnerSection .partnerformWrapper form.db .radioGroup {
      margin-bottom: 0;
    }
  }

  .reloadedPartners .partnerSection .partnerformWrapper form.db .dbTable {
    display: none;
  }

  @media (min-width: ${tablet}px) {
    .reloadedPartners .partnerSection .partnerformWrapper form.db .dbTable {
      display: initial;
      width: 100%;
      float: left;
    }

    .reloadedPartners .partnerSection .partnerformWrapper form.db .dbTable .dbRow {
      display: initial;
      width: 100%;
    }

    .reloadedPartners .partnerSection .partnerformWrapper form.db .dbTable .dbRow .dbCell {
      position: relative;
      float: left;
    }

    .reloadedPartners .partnerSection .partnerformWrapper form.db .dbTable .dbRow .dbCell.db-date {
      max-width: 15rem;
    }

    .reloadedPartners .partnerSection .partnerformWrapper form.db .dbTable .dbRow .dbCell.db-date input {
      max-width: 15rem;
    }

    .reloadedPartners .partnerSection .partnerformWrapper form.db .dbTable .dbRow .dbCell.db-clock {
      vertical-align: top;
      max-width: 6rem;
      padding-left: 1rem;
    }

    .reloadedPartners .partnerSection .partnerformWrapper form.db .dbTable .dbRow .dbCell.db-timewhish {
      white-space: nowrap;
      min-width: 10rem;
      float: right;
    }

    .reloadedPartners .partnerSection .partnerformWrapper form.db .dbTable .dbRow .dbCell.db-timewhish label:last-child {
      clear: none;
      margin-left: 3rem;
    }

    .reloadedPartners .partnerSection .partnerformWrapper form.db .dbTable .dbRow .dbCell .radioGroup {
      top: 0;
      right: 0;
      width: 100%;
    }
  }

  .reloadedPartners .partnerSection .partnerformWrapper form.db .dbTableMobile {
    display: block;
  }

  @media (min-width: ${tablet}px) {
    .reloadedPartners .partnerSection .partnerformWrapper form.db .dbTableMobile {
      display: none;
    }
  }

  .reloadedPartners .partnerSection .partnerformWrapper form.db .dbTableMobile .arrival {
    clear: both;
    float: left;
    width: 100%;
  }

  .reloadedPartners .partnerSection .partnerformWrapper form.db .dbTableMobile .db-travelday-label {
    clear: both;
    display: block;
  }

  .reloadedPartners .partnerSection .partnerformWrapper form.db .dbTableMobile .db-mobileClock {
    width: 100%;
  }

  .reloadedPartners .partnerSection .partnerformWrapper form.airbnb label:first-child {
    position: relative;
  }

  @media (min-width: ${tablet}px) {
    .reloadedPartners .partnerSection .partnerformWrapper form.airbnb label:first-child {
      margin-left: -4.7rem;
    }

    .reloadedPartners .partnerSection .partnerformWrapper form.airbnb label:first-child.travelDepLabel {
      float: right;
      top: 0;
    }
  }

  .reloadedPartners .partnerSection .partnerformWrapper form.airbnb label:nth-of-type(2) {
    position: relative;
    top: 0;
  }

  @media (min-width: ${tablet}px) {
    .reloadedPartners .partnerSection .partnerformWrapper form.airbnb label:nth-of-type(2) {
      margin-left: -7.7rem;
    }
  }

  .reloadedPartners .partnerSection .partnerformWrapper form.airbnb label:nth-of-type(3) {
    position: relative;
    top: 0;
  }

  @media (min-width: ${tablet}px) {
    .reloadedPartners .partnerSection .partnerformWrapper form.airbnb label:nth-of-type(3) {
      float: none;
      margin-left: 4rem;
    }

    .reloadedPartners .partnerSection .partnerformWrapper form.airbnb label:nth-of-type(3).travelRooms {
      float: left;
      margin-left: -6.3rem;
    }
  }

  .reloadedPartners .partnerSection .icon-calendar {
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    display: block;
    float: right;
    position: relative;
    top: -46px;
    right: 10px;
    padding: 0;
    margin: 0;
    height: 2.4rem;
    width: 2.4rem;
    cursor: pointer;
    background-image: var(--bg-routenplaner-calendar);
  }

  @media (min-width: ${tablet}px) {
    .reloadedPartners .partnerSection .icon-calendar {
      position: absolute;
      top: 1rem;
      right: 1rem;
    }
  }

  .arrival-label, .db-travelday-label {
    display: none;
  }

  @media (min-width: ${tablet}px) {
    .arrival-label, .db-travelday-label {
      display: block;
    }

    .db .arrival-label, .db .db-travelday-label {
      margin-left: -7.7rem;
      clear: both;
    }
  }

  .triv_departure, .triv_arrival {
    cursor: pointer;
  }

  .travelTable {
    display: none;
  }

  @media (min-width: ${tablet}px) {
    .travelTable {
      display: table;
      width: 100%;
      float: left;
    }
  }

  .travelTable .travelRow {
    display: block;
  }

  @media (min-width: ${tablet}px) {
    .travelTable .travelRow {
      display: table-row;
    }
  }

  .travelTable .travelRow .travelCell {
    display: block;
  }

  @media (min-width: ${tablet}px) {
    .travelTable .travelRow .travelCell {
      position: relative;
      width: auto;
    }
  }

  .travelTable .travelRow .travelDepLabelWrapper {
    display: block;
  }

  @media (min-width: ${tablet}px) {
    .travelTable .travelRow .travelDepLabelWrapper {
      display: table-cell;
      position: relative;
      width: 20%;
      vertical-align: top;
    }
  }

  .travelTable .travelRow .travelDepInput {
    display: block;
  }

  @media (min-width: ${tablet}px) {
    .travelTable .travelRow .travelDepInput {
      padding-left: 20px;
      position: relative;
      width: auto;
    }
  }

  .travelTableMobile {
    display: block;
  }

  @media (min-width: ${tablet}px) {
    .travelTableMobile {
      display: none;
    }
  }

  .travelTableMobile .arrival, .travelTableMobile .departure {
    clear: both;
    float: left;
    width: 100%;
  }

  /* Tool module extracted */
  [data-mod-name='tool'] {
    position: relative;
    background-color: var(--page-background);
    clear: both;
  }
  .page__content [data-mod-name='tool'] {
    margin: 0 0 var(--gap);
    border: 0.1rem solid var(--separator-default);
  }
  [data-mod-name='tool'] .tool-header,
  [data-mod-name='tool'] .tool-content {
    padding: 0 var(--gap);
  }
  [data-mod-name='tool'] .tool-header {
    font-size: 1.4rem;
    line-height: 4.2rem;
    font-weight: 700;
    min-height: 4.2rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  [data-mod-name='toc'] {
    padding-bottom: 0.8rem;
  }
  [data-mod-name='tool'] .tool-content ul {
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 1.6rem;
    line-height: 2.2rem;
  }
  [data-mod-name='toc'] .list.master li {
    padding-left: calc(3 * var(--bullet-size));
    margin: 1.8rem 0;
    font-size: 1.6rem;
    min-height: calc(calc(4rem - var(--line-height)) / 2);
  }
  [data-mod-name='toc'] .list.master li:first-child {
    margin-top: 0.2rem;
  }
  [data-mod-name='toc'] .list.master li::before {
    content: '';
    padding: 0;
    display: inline-block;
    width: var(--bullet-size);
    height: var(--bullet-size);
    margin-top: calc(calc(var(--line-height) / 2) - calc(var(--bullet-size) / 2) - 0.1rem);
    margin-left: calc(-3 * var(--bullet-size));
    margin-right: calc(2 * var(--bullet-size));
    vertical-align: top;
    background: var(--cp-grey-light-3);
  }
  @media (min-width: ${tablet}px) {
    [data-mod-name='toc'] .list.master li {
      font-size: 1.8rem;
      min-height: calc(calc(3.4rem - var(--line-height)) / 2);
      margin: 1.2rem 0;
    }
    [data-mod-name='tool'] .tool-header {
      font-size: 1.8rem;
    }
  }
  a {
    text-decoration: none;
  }
`;
