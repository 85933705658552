import {html} from 'lit';
import {UrlBrandData} from './urlBrandData';

export function premiumPartner(urlBrandData: UrlBrandData) {
  const logoPath = urlBrandData.assetsPath + '/routenplaner/logo-viamichelin_.png';
  return html`
    <div class="premiumPartner">
      <a href="${urlBrandData.viamichelinLogoUrl}"
        title="michelin"
        target="_blank"
        rel="nofollow">
        <img src="${logoPath}" alt="ViaMichelin" title="ViaMichelin">
      </a>

      <p class="partnerText">
        ViaMichelin - Testsieger im Vergleichstest der PCgo. Neben
        <a href="${urlBrandData.viamichelinTextUrl1}" target="_blank" rel="nofollow">Routenberechnung</a>
        finden Sie Zusatzinformationen zu
        <a href="${urlBrandData.viamichelinTextUrl2}" target="_blank" rel="nofollow">Gastronomie</a>,
        <a href="${urlBrandData.viamichelinTextUrl3}" target="_blank" rel="nofollow">Hotels</a>
        und <a href="${urlBrandData.viamichelinTextUrl4}" target="_blank" rel="nofollow">Sehenswürdigkeiten</a>.
      </p>
    </div>
  `;
}
