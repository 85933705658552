import UrlPath from '../../ts/services/url-path';

class UrlBrandData {
  brandId: string;
  brandName: string;
  assetsPath: string;
  uriRegion: string;
  viamichelinLogoUrl: string;
  viamichelinBtnUrl: string;
  viamichelinTextUrl1: string;
  viamichelinTextUrl2: string;
  viamichelinTextUrl3: string;
  viamichelinTextUrl4: string;
  logoUrl11880: string;
  btnUrl11880: string;
  routenplaner24LogoUrl: string;
  routenplaner24BtnUrl: string;
  meinestadtLogoUrl: string;
  meinestadtBtnUrl: string;
  hereLogoUrl: string;
  hereBtnUrl: string;
  reiseplanungLogoUrl: string;
  reiseplanungBtnUrl: string;
  dbLogoUrl: string;
  dbBtnUrl: string;

  constructor(brandId: string, brandName: string) {
    this.brandId = brandId;
    this.brandName = brandName;
    this.assetsPath = UrlPath.moduleAssets;

    let site = 'webde';
    let region = 'de';
    let linkid = 2476119;

    switch (brandId) {
      case 'gmx':
        site = 'gmx';
        linkid = 2476120;
        break;
      case 'gmxat':
        site = 'gmx';
        region = 'at';
        linkid = 2476120;
        break;
      case 'gmxch':
        site = 'gmx';
        region = 'ch';
        linkid = 2476120;
        break;
      case 'eue':
        site = '1und1';
        linkid = 2476120;
        break;
    }
    this.uriRegion = region;
    this.viamichelinLogoUrl = `https://t.uimserv.net/drp_r/?md=uid&et=WR&evtid=864&mediaID=930&mpID=4&site=${site}&region=${region}&sc=Routenplaner/michelin/&lpos=anbieterauswahl_logo&att1=&att2=&att3=&extEvtID=&durl=https%3A%2F%2Fwww.viamichelin.de%2Fweb%2FRoutenplaner%3Ffrom%3D${brandId}%26utm_campaign=Routenplaner_${brandId}_ViamichelinLogo`;
    this.viamichelinBtnUrl = `https://t.uimserv.net/drp_r/?md=uid&et=WR&evtid=864&mediaID=930&mpID=4&site=${site}&region=${region}&sc=Routenplaner/michelin/&lpos=anbieterauswahl_button&att1=&att2=&att3=&extEvtID=&durl=`;
    this.viamichelinTextUrl1 = `https://t.uimserv.net/drp_r/?md=uid&et=WR&evtid=864&mediaID=930&mpID=4&site=${site}&region=${region}&sc=Routenplaner/michelin/&lpos=anbieterauswahl_textlink&att1=&att2=&att3=&extEvtID=&durl=https%3A%2F%2Fwww.viamichelin.de%2Fweb%2FRoutenplaner%3Ffrom%3D${brandId}%26utm_campaign=Routenplaner_${brandId}_textLink_routenberechnung`;
    this.viamichelinTextUrl2 = `https://t.uimserv.net/drp_r/?md=uid&et=WR&evtid=864&mediaID=930&mpID=4&site=${site}&region=${region}&sc=Routenplaner/michelin/&lpos=anbieterauswahl_textlink&att1=&att2=&att3=&extEvtID=&durl=https%3A%2F%2Fguide.michelin.com%2Fde%2Fde%3Ffrom%3D${brandId}%26utm_campaign=Routenplaner_${brandId}_textLink_gastronomie`;
    this.viamichelinTextUrl3 = `https://t.uimserv.net/drp_r/?md=uid&et=WR&evtid=864&mediaID=930&mpID=4&site=${site}&region=${region}&sc=Routenplaner/michelin/&lpos=anbieterauswahl_textlink&att1=&att2=&att3=&extEvtID=&durl=https%3A%2F%2Fwww.viamichelin.de%2Fweb%2FHotels%3Ffrom%3D${brandId}%26utm_campaign=Routenplaner_${brandId}_textLink_hotels`;
    this.viamichelinTextUrl4 = `https://t.uimserv.net/drp_r/?md=uid&et=WR&evtid=864&mediaID=930&mpID=4&site=${site}&region=${region}&sc=Routenplaner/michelin/&lpos=anbieterauswahl_textlink&att1=&att2=&att3=&extEvtID=&durl=https%3A%2F%2Fwww.viamichelin.de%2Fweb%2FTouristische-Attraktionen%3Ffrom%3D${brandId}%26utm_campaign=Routenplaner_${brandId}_textLink_seheutmwuerdigkeiten`;
    this.logoUrl11880 = `https://t.uimserv.net/drp_r/?md=uid&et=WR&evtid=864&mediaID=930&mpID=4&site=${site}&region=${region}&sc=Routenplaner/11880.com/&lpos=anbieterauswahl_logo&att1=&att2=&att3=&extEvtID=&durl=https%3A%2F%2Fwww.klicktel.de%2F`;
    this.btnUrl11880 = `https://t.uimserv.net/drp_r/?md=uid&et=WR&evtid=864&mediaID=930&mpID=4&site=${site}&region=${region}&sc=Routenplaner/11880.com/&lpos=anbieterauswahl_button&att1=&att2=&att3=&extEvtID=&durl=`;
    this.routenplaner24LogoUrl = `https://t.uimserv.net/drp_r/?md=uid&et=WR&evtid=864&mediaID=930&mpID=4&site=${site}&region=${region}&sc=Routenplaner/routenplaner24/&lpos=anbieterauswahl_logo&att1=&att2=&att3=&extEvtID=&durl=https%3A%2F%2Fwww.routenplaner24.de%2F`;
    this.routenplaner24BtnUrl = `https://t.uimserv.net/drp_r/?md=uid&et=WR&evtid=864&mediaID=930&mpID=4&site=${site}&region=${region}&sc=Routenplaner/routenplaner24/&lpos=anbieterauswahl_button&att1=&att2=&att3=&extEvtID=&durl=https%3A%2F%2Fwww.routenplaner24.de%2F`;
    this.meinestadtLogoUrl = `https://t.uimserv.net/drp_r/?md=uid&et=WR&evtid=864&mediaID=930&mpID=4&site=${site}&region=${region}&sc=Routenplaner/meinestadtde/&lpos=anbieterauswahl_logo&att1=&att2=&att3=&extEvtID=&durl=https%3A%2F%2Fwww.meinestadt.de%2F`;
    this.meinestadtBtnUrl = `https://t.uimserv.net/drp_r/?md=uid&et=WR&evtid=864&mediaID=930&mpID=4&site=${site}&region=${region}&sc=Routenplaner/meinestadtde/&lpos=anbieterauswahl_button&att1=&att2=&att3=&extEvtID=&durl=`;
    this.hereLogoUrl = `https://t.uimserv.net/drp_r/?md=uid&et=WR&evtid=864&mediaID=930&mpID=4&site=${site}&region=${region}&sc=Routenplaner/here/&lpos=anbieterauswahl_logo&att1=&att2=&att3=&extEvtID=&durl=https%3A%2F%2Fwww.here.com%2F`;
    this.hereBtnUrl = `https://t.uimserv.net/drp_r/?md=uid&et=WR&evtid=864&mediaID=930&mpID=4&site=${site}&region=${region}&sc=Routenplaner/here/&lpos=anbieterauswahl_button&att1=&att2=&att3=&extEvtID=&durl=https%3A%2F%2Fwww.here.com%2F`;
    this.reiseplanungLogoUrl = `https://t.uimserv.net/drp_r/?md=uid&et=WR&evtid=864&mediaID=930&mpID=4&site=${site}&region=${region}&sc=Routenplaner/reiseplanung/&lpos=anbieterauswahl_logo&att1=&att2=&att3=&extEvtID=&durl=https%3A%2F%2Fwww.reiseplanung.de%2F`;
    this.reiseplanungBtnUrl = `https://t.uimserv.net/drp_r/?md=uid&et=WR&evtid=864&mediaID=930&mpID=4&site=${site}&region=${region}&sc=Routenplaner/reiseplanung/&lpos=anbieterauswahl_button&att1=&att2=&att3=&extEvtID=&durl=https%3A%2F%2Fwww.reiseplanung.de%2F`;
    this.dbLogoUrl = `https://www.awin1.com/awclick.php?gid=372474&mid=14964&awinaffid=570453&linkid=${linkid}&clickref=`;
    this.dbBtnUrl = `https://t.uimserv.net/drp_r/?md=uid&et=WR&evtid=864&mediaID=930&mpID=4&site=${site}&region=${region}&sc=Routenplaner/deutsche_bahn/&lpos=reloaded_button&att1=&att2=&att3=&extEvtID=&durl=`;
  }
}

export {UrlBrandData};
