import {html} from 'lit';
import {UrlBrandData} from './urlBrandData';

export function partners(urlBrandData: UrlBrandData) {
  const logoPath11880 = urlBrandData.assetsPath + '/routenplaner/logo-11880.svg';
  const routenplanerdeLogoPath = urlBrandData.assetsPath + '/routenplaner/logo-routenplanerde_.png';
  const meinestadtLogoPath = urlBrandData.assetsPath + '/routenplaner/logo-meinestadt_.png';
  const hereLogoPath = urlBrandData.assetsPath + '/routenplaner/logo-here_.png';
  const reiseplanungLogoPath = urlBrandData.assetsPath + '/routenplaner/logo-reiseplanungde_.png';
  return html`
    <div class="partners">
      <slot name="weitere_routenplaner_im_web"></slot>

      <div class="partnerSection">
        <a href="${urlBrandData.logoUrl11880}"
          title="11880.com"
          target="_blank"
          rel="nofollow">
          <img src="${logoPath11880}" alt="11880.com">
        </a>

        <div class="partnerTextWrapper">
          <h4 class="partnerName">11880.com</h4>

          <p class="partnerText">11880.com – Machen Sie Ihre Routenplanung für Deutschland und Europa mit
            11880.com.
            <a href="https://www.11880.com/routenplaner" target="_blank" rel="nofollow">Zu 11880.com</a>
          </p>
        </div>
      </div>

      <div class="partnerSection">
        <a href="${urlBrandData.routenplaner24LogoUrl}"
          target="_blank"
          rel="nofollow">
          <img src="${routenplanerdeLogoPath}" alt="Routenplaner 24" title="Routenplaner 24">
        </a>

        <div class="partnerTextWrapper">
          <h4 class="partnerName">Routenplaner 24</h4>

          <p class="partnerText">Online Routenplanung und Stadtpläne für ganz Deutschland und Europa.
            <a href="http://www.routenplaner24.de/" target="_blank" rel="nofollow">Zu Routenplaner24</a>
          </p>
        </div>
      </div>

      <div class="partnerSection">
        <a href="${urlBrandData.meinestadtLogoUrl}"
          target="_blank"
          rel="nofollow">
          <img src="${meinestadtLogoPath}" alt="meinestadt.de" title="meinestadt.de">
        </a>

        <div class="partnerTextWrapper">
          <h4 class="partnerName">meinestadt.de</h4>

          <p class="partnerText">Der Routenplaner für alle 12.241 deutschen Städte.
            <a href="http://www.meinestadt.de/deutschland/stadtplan/routenplaner" target="_blank" rel="nofollow">Zu
                meinestadt.de</a>
          </p>
        </div>
      </div>

      <div class="partnerSection">
        <a href="${urlBrandData.hereLogoUrl}"
          title="here.com"
          target="_blank"
          rel="nofollow">
          <img src="${hereLogoPath}" alt="here.com" title="here.com">
        </a>

        <div class="partnerTextWrapper">
          <h4 class="partnerName">here.com</h4>

          <p class="partnerText">Deutschland, Europa und viele andere Länder der Welt. Wichtige Punkte gleich
            mit anzeigen lassen.
            <a href="http://www.here.com/" target="_blank" rel="nofollow">Zu here.com</a>
          </p>
        </div>
      </div>

      <div class="partnerSection">
        <a href="${urlBrandData.reiseplanungLogoUrl}"
          title="reiseplanung.de"
          target="_blank"
          rel="nofollow">
          <img src="${reiseplanungLogoPath}" alt="reiseplanung.de" title="reiseplanung.de">
        </a>

        <div class="partnerTextWrapper">
          <h4 class="partnerName">reiseplanung.de</h4>

          <p class="partnerText">Planen Sie Ihre Reise mit einem der besten Online-Routenplaner. Gute Reise!
            <a href="http://www.reiseplanung.de/" target="_blank" rel="nofollow">Zu reiseplanung.de</a>
          </p>
        </div>
      </div>

    </div>
  `;
}
